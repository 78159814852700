import React from 'react'

const PatentsChat = () => {
  return (
    <a href="https://patents.chat" target="_blank"><div className="text-center py-8 rounded border border-black my-2 px-2 bg-green-100 hover:bg-green-500 hover:text-white">
      <p className="text-lg font-bold">Patents.Chat</p>
      <p className="text-md">Chat with US patent documents for quick insights</p>
    </div></a>
  )
}

export default PatentsChat
