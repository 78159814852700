import axios from 'axios'
import config from './config'

let BASE_URL = null
// eslint-disable-next-line
if (process.env.NODE_ENV === 'development') {
  BASE_URL = config.BASE_URL
} else {
  BASE_URL = config.PROD_URL
}

const performRag = async (query, useVicuna, clusterId) => {
  const response = await axios.post(`${BASE_URL}/perform_rag`, { query, useVicuna, clusterId })

  return response.data
}

const getJobStatus = async (query) => {
  const response = await axios.post(`${BASE_URL}/job_status`, { query })

  return response.data
}

const extractKnowledgeFromFile = async (query) => {
  try {
    const response = await axios.post(`${BASE_URL}/extract_knowledge_from_file`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error uploading file: ', error)
  }
  //  const response = await axios.post(`${BASE_URL}/extract_knowledge_from_file`, { query })
  return ''
}

const extractKnowledgeFromUrl = async (query) => {
  const response = await axios.post(`${BASE_URL}/extract_knowledge_from_url`, { query })

  return response.data
}

const performHybridSearch = async (query, clusterId) => {
  const response = await axios.post(`${BASE_URL}/perform_hybrid_search`, { query, clusterId })

  return response.data
}

const deleteEmbeddings = async () => {
  const response = await axios.post(`${BASE_URL}/delete_embeddings`, {})

  return response.data
}

const fetchClusters = async () => {
  const response = await axios.get(`${BASE_URL}/fetch_clusters`)

  return response.data
}

const addCluster = async (cluster) => {
  const response = await axios.post(`${BASE_URL}/add_cluster`, { cluster })

  return response.data
}

const deleteCluster = async (cluster_id) => {
  const response = await axios.post(`${BASE_URL}/delete_cluster`, { cluster_id })

  return response.data
}

export {
  BASE_URL,
  getJobStatus,
  extractKnowledgeFromFile,
  extractKnowledgeFromUrl,
  performRag,
  performHybridSearch,
  deleteEmbeddings,
  fetchClusters,
  addCluster,
  deleteCluster,
}
