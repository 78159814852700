import React from 'react'

const AssessmentsChat = () => {
  return (
    <a href="https://assessments.chat" target="_blank"><div className="text-center py-8 pointer rounded border border-black my-2 px-2 bg-blue-100 hover:bg-blue-500 hover:text-white">
      <p className="text-lg font-bold">Standard Assessments</p>
      <p className="text-md">Chat-based assessments for school, with automated grading</p>
    </div></a>
  )
}

export default AssessmentsChat
