import React from 'react'

const DescriptionText = () => {
  return (
    <div className="text-center py-8">
      <p className="text-lg">Custom Generative AI Applications</p>
      <p className="text-sm">Contact us at info@forgen.ai</p>
    </div>
  )
}

export default DescriptionText
