// components/Header.js
import React from 'react'

const Header = () => {
  return (
    <header className="text-center py-8">
      <img src="forgen_ai_2.png" alt="Logo" className="mx-auto" />
      <h2 className="text-xl mt-2 italic ">For Generative AI Solutions</h2>
    </header>
  )
}

export default Header
